import { render, staticRenderFns } from "./DropdownLanguage.vue?vue&type=template&id=00da8824&"
import script from "./DropdownLanguage.vue?vue&type=script&lang=js&"
export * from "./DropdownLanguage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemTitle})
