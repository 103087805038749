<template>
  <v-list flat class="px-1">
    <v-list-item-group v-model="selectedLang" color="primary">
      <template v-for="(item, i) in languages">
        <v-list-item :key="i" @click="selectedLanguage(item.lang)">
          <v-list-item-avatar
            color="light-blue lighten-5 rounded"
            tile
            size="30"
          >
            <v-img contain height="10px" :src="item.flag" alt="" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTDropdownLanguage",
  data() {
    return {
      languages: i18nService.languages,
      selectedLang: this.activeLanguage
    };
  },
  methods: {
    selectedLanguage(lang) {
      i18nService.setActiveLanguage(lang);

      this.$emit(
        "language-changed",
        this.languages.find(val => {
          return val.lang === lang;
        })
      );
      window.dispatchEvent(
        new CustomEvent("langChange", {
          detail: {
            newLang: lang
          }
        })
      );
      this.$util.EventBus.$emit("langChanged", lang);
      this.$i18n.locale = lang;
      //window.location.reload();
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    }
  },
  mounted() {
    // console.log("mounted language modal");
  },
  activated() {
    // console.log("activated language modal");
  },
  computed: {
    activeLanguage() {
      return i18nService.getActiveLanguage();
    }
    /* selectedLang() {
      for (var i = 0; i < this.languages.length; i++) {
        // console.log(
          this.isActiveLanguage(this.languages[i].lang),
          this.languages[i].lang,
          this.activeLanguage
        );
        if (this.isActiveLanguage(this.languages[i].lang)) return i;
      }
      return 0;
    },*/
  }
};
</script>
